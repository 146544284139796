/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.tooltip {pointer-events: none; position: absolute; z-index: 9; font-size: 12px; padding: 8px; background: #000; color: #fff; min-width: 160px; max-height: 240px; overflow-y: hidden;}
    .tooltip.interactive {pointer-events: all; background: #fcfcfc; color: #444; overflow-y: auto;} */


/* .MuiCheckbox-colorSecondary.Mui-checked{
  color: #2C7873!important;
} */

/* #root > div > div.makeStyles-content-2 > div.makeStyles-bottompanel-188.makeStyles-fullpanel-189 > div.btmtable > div > div.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-230.MuiToolbar-gutters */


.btmtable > .MuiPaper-root > .MuiToolbar-regular:first-child{
  position: absolute;
  right: 0;
  margin-top: -60px;
}
.dTable .MuiTableRow-head{
  height: auto;
}